import type { RecentTransactionsQuery } from '@noah-labs/fe-shared-data-access-wallet';
import { useRecentTransactionsQuery } from '@noah-labs/fe-shared-data-access-wallet';
import { useUserInitUi } from '@noah-labs/fe-shared-feature-user';
import type { TpCryptoCurrencyUI, TpCryptoNetworkUI } from '@noah-labs/fe-shared-ui-shared';
import {
  cryptoCurrencyFromCode,
  cryptoNetworkFromId,
  useWalletParams,
} from '@noah-labs/fe-shared-ui-shared';
import { SortDirection, TransactionStatus } from '@noah-labs/shared-schema-gql';
import { compareStrings } from '@noah-labs/shared-util-vanilla';

export type TpRecentAddresses = {
  address: string;
  cryptoCurrency: TpCryptoCurrencyUI;
  cryptoNetwork: TpCryptoNetworkUI;
};

function isValidAndUnique(
  ad: TpRecentAddresses | undefined,
  index: number,
  self: (TpRecentAddresses | undefined)[],
): boolean {
  return Boolean(ad) && self.findIndex((i) => compareStrings(i?.address, ad?.address)) === index;
}

function selectRecentAddresses(
  data: RecentTransactionsQuery,
  limit: number,
): TpRecentAddresses[] | undefined {
  return data.transactionsTemp.items
    .map((t) =>
      t.Destination
        ? {
            address: t.Destination.Address,
            cryptoCurrency: cryptoCurrencyFromCode(t.Currency),
            cryptoNetwork: cryptoNetworkFromId(t.Network),
          }
        : undefined,
    )
    .filter(isValidAndUnique)
    .slice(0, limit) as TpRecentAddresses[];
}

const RECENT_ADDRESS_LIMIT = 5;

export function useRecentAddresses(): TpRecentAddresses[] {
  const { cryptoCurrency } = useWalletParams();
  const { data: userInitData } = useUserInitUi();
  const { data: addresses } = useRecentTransactionsQuery(
    {
      Input: {
        Currency: cryptoCurrency.code,
        filter: {
          Destination: {
            attributeExists: true,
          },
          SourceAccountID: {
            contains: userInitData?.userProfile.UserID,
          },
          Status: {
            eq: TransactionStatus.Settled,
          },
        },
        paging: {
          limit: RECENT_ADDRESS_LIMIT * 2, // fetch more addresses than needed to account for duplicates
          sortDirection: SortDirection.DESC,
        },
      },
    },
    {
      enabled: !!userInitData?.userProfile.UserID,
      select: (data) => selectRecentAddresses(data, RECENT_ADDRESS_LIMIT),
    },
  );

  return addresses || [];
}
